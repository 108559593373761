import { Box, ChakraProvider, Flex, Grid, Heading } from '@chakra-ui/react';

import { ColorModeSwitcher } from './Components/ColorModeSwitcher';
import BigMonthlyGoal from './Components/BigMonthlyGoal';
import Responsible from './Components/Responsible';
import RamalQueue from './Components/RamalQueue';
import Quotations from './Components/Quotations';
import Birthday from './Components/Birthday';
import Ramais from './Components/Ramais';
import Sales from './Components/Sales';
import Card from './Components/Card';
import Goal from './Components/Goal';
import theme from './Styles/theme';
import './Styles/style.css';

export const App = (): JSX.Element => (
  <ChakraProvider theme={theme}>
    <Grid
      templateColumns={{
        base: '1fr',
        md: 'repeat(4, 1fr)',
      }}
      pt={5}
      px={5}
      gap={5}
      w="100%"
    >
      <Card>
        <Flex direction="column" gap={4} alignItems="center">
          <Heading size="md">Meta mensal</Heading>
          <Goal type="mensal" />
        </Flex>
      </Card>
      <Card>
        <Flex direction="column" gap={4} alignItems="center">
          <Heading size="md">Meta trimestral</Heading>
          <Goal type="trimestral" />
          <Heading size="md">Meta anual</Heading>
          <Goal type="anual" />
        </Flex>
      </Card>
      <Card>
        <Ramais />
      </Card>
      <Grid templateRows="auto 1fr" gap={5}>
        <Grid templateColumns="1fr max-content" gap={5}>
          <Card>
            <RamalQueue />
          </Card>
          <Card>
            <Flex h="100%" alignItems="center">
              <ColorModeSwitcher />
            </Flex>
          </Card>
        </Grid>
        <Card>
          <BigMonthlyGoal />
        </Card>
      </Grid>
    </Grid>
    <Grid
      templateColumns={{
        base: '1fr',
        md: 'repeat(4, 1fr)',
      }}
      p={5}
      gap={5}
      w="100%"
    >
      <Box>
        <Card>
          <Responsible />
        </Card>
      </Box>
      <Flex direction="column" gap={5}>
        <Card>
          <Sales />
        </Card>
        <Card>
          <Quotations />
        </Card>
      </Flex>
      <Box>
        <Card>
          <Birthday index={0} />
        </Card>
      </Box>
      <Flex direction="column" gap={5}>
        <Card>
          <Birthday index={1} />
        </Card>
        <Card>
          <Birthday index={2} />
        </Card>
      </Flex>
    </Grid>
  </ChakraProvider>
);
