import React, { useCallback, useEffect, useState } from 'react';
import { Flex, Grid, Heading, SkeletonText, useToast } from '@chakra-ui/react';
import IRamal from '../../Types/IRamal';
import api from '../../Services/api';

const Ramais: React.FC = () => {
  const toast = useToast();

  const [loading, setLoading] = useState(true);
  const [ramais, setRamais] = useState<IRamal[]>();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.get('/teravoz/fila_ramais');
      setRamais(data);
    } catch {
      toast({
        title: 'Erro',
        status: 'error',
        position: 'top',
        description: 'Falha ao carregar a lista de ramais',
      });
    } finally {
      setLoading(false);
    }
  }, [toast]);

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 2500);
    return () => clearInterval(interval);
  }, [fetchData]);

  const getStatus = useCallback((status: string) => {
    switch (status) {
      case 'livre':
        return 'on';
      case 'em uso':
        return 'busy';
      case 'chamando':
        return '...';
      default:
        return 'out';
    }
  }, []);

  return (
    <Flex direction="column" gap={2}>
      <Grid w="100%" templateColumns="1fr 2fr 1fr" columnGap={5} rowGap={2}>
        <Heading size="md">Ramal</Heading>
        <Heading size="md">Usuário</Heading>
        <Heading size="md">Status</Heading>
        {ramais?.map((ramal) => (
          <>
            <Heading
              color={ramal.status === 'livre' ? 'green.600' : 'orange.500'}
              size="md"
              fontWeight="medium"
            >
              {ramal.number}
            </Heading>
            <Heading
              color={ramal.status === 'livre' ? 'green.600' : 'orange.500'}
              size="md"
              fontWeight="medium"
            >
              {ramal.name}
            </Heading>
            <Heading
              color={ramal.status === 'livre' ? 'green.600' : 'orange.500'}
              size="md"
              fontWeight="medium"
              textAlign="center"
            >
              {getStatus(ramal.status)}
            </Heading>
          </>
        ))}
      </Grid>
      {loading && <SkeletonText noOfLines={12} rowGap={2} />}
    </Flex>
  );
};

export default Ramais;
