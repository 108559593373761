import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Flex, Heading, Skeleton, useToast } from '@chakra-ui/react';
import api from '../../Services/api';

const BigMonthlyGoal: React.FC = () => {
  const toast = useToast();

  const [loading, setLoading] = useState(true);
  const [amount, setAmount] = useState(0);
  const [goal, setGoal] = useState(0);

  const fetchData = useCallback(async () => {
    try {
      const { data: amountResult } = await api.get('/vhsys/pedidos_mensal');
      setAmount(amountResult.data);
      const { data: goalResult } = await api.get(
        '/settings/get_metas?type=meta_mensal'
      );
      setGoal(goalResult.data.meta_mensal);
      setLoading(false);
    } catch {
      toast({
        title: 'Erro',
        status: 'error',
        position: 'top',
        description: 'Falha ao carregar a meta mensal',
      });
    }
  }, [toast]);

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 960000);
    return () => clearInterval(interval);
  }, [fetchData]);

  const leftAmountPercent = useMemo(() => {
    return ((goal - amount) / goal) * 100;
  }, [amount, goal]);

  return (
    <Flex
      bgSize="cover"
      bgRepeat="no-repeat"
      borderRadius="md"
      alignItems="flex-end"
      justifyContent="flex-end"
      bgImage="/onca.jpeg"
      h="100%"
      p={5}
    >
      <Flex direction="column" color="white">
        <Heading size="md">faltam</Heading>
        <Skeleton isLoaded={!loading}>
          <Heading size="4xl">
            {leftAmountPercent.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
            %
          </Heading>
        </Skeleton>
      </Flex>
    </Flex>
  );
};

export default BigMonthlyGoal;
