import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    green: {
      '50': '#F1FFE5',
      '100': '#D8FFB8',
      '200': '#BFFF8A',
      '300': '#A6FF5C',
      '400': '#8DFF2E',
      '500': '#74FF00',
      '600': '#5DCC00',
      '700': '#469900',
      '800': '#2E6600',
      '900': '#173300',
    },
    orange: {
      '50': '#FFF1E6',
      '100': '#FED8B9',
      '200': '#FDBF8B',
      '300': '#FDA65E',
      '400': '#FC8D31',
      '500': '#FC7403',
      '600': '#C95D03',
      '700': '#974602',
      '800': '#652E01',
      '900': '#321701',
    },
  },
  fonts: {
    body: 'Quicksand, system-ui, sans-serif',
    heading: 'Quicksand, Georgia, serif',
    mono: 'Menlo, monospace',
  },
});

export default theme;
