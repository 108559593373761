import React, { useCallback, useEffect, useState } from 'react';
import { Flex, Heading, Skeleton, useToast } from '@chakra-ui/react';

import api from '../../Services/api';

const Sales: React.FC = () => {
  const toast = useToast();

  const [loading, setLoading] = useState(true);
  const [salesNumber, setSalesNumber] = useState<number>();

  const fetchData = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await api.get('/vhsys/pedidos');
      setSalesNumber(data);
      setLoading(false);
    } catch {
      toast({
        title: 'Erro',
        status: 'error',
        position: 'top',
        description: 'Falha ao carregar pedidos',
      });
    }
  }, [toast]);

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 120000);
    return () => clearInterval(interval);
  }, [fetchData]);

  return (
    <Flex gap={2} alignItems="center" justifyContent="space-between">
      <Skeleton isLoaded={!loading}>
        <Heading size="md">Pedidos</Heading>
      </Skeleton>
      <Skeleton isLoaded={!loading}>
        <Heading color="green.600" size="md">
          {salesNumber}
        </Heading>
      </Skeleton>
    </Flex>
  );
};

export default Sales;
