import React from 'react';
import { Box } from '@chakra-ui/react';

const Card: React.FC = ({ children }) => {
  return (
    <Box borderRadius="md" boxShadow="md" p={5}>
      {children}
    </Box>
  );
};

export default Card;
