import React, { useMemo } from 'react';
import { Box, Image, ImageProps } from '@chakra-ui/react';

const Avatar: React.FC<ImageProps> = ({ src, ...props }) => {
  const source = useMemo(() => src || '/avatar.png', [src]);
  return (
    <Box w="64px" h="64px">
      <Image
        objectFit="cover"
        w="100%"
        h="100%"
        rounded="full"
        src={source}
        {...props}
      />
    </Box>
  );
};

export default Avatar;
