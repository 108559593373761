import React, { useCallback, useEffect, useState } from 'react';
import { Flex, Heading, SkeletonText, useToast } from '@chakra-ui/react';
import api from '../../Services/api';

const RamalQueue: React.FC = () => {
  const toast = useToast();

  const [size, setSize] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.get('/teravoz/fila');
      setSize(data.data);
      setLoading(false);
    } catch {
      toast({
        title: 'Erro',
        status: 'error',
        position: 'top',
        description: 'Falha ao carregar ligações em espera',
      });
    }
  }, [toast]);

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 2500);
    return () => clearInterval(interval);
  }, [fetchData]);

  return (
    <SkeletonText isLoaded={!loading} noOfLines={2}>
      <Flex direction="column" gap={3} alignItems="center">
        <Heading size="md">Ligações em espera</Heading>
        <Heading size="lg" color={size > 0 ? 'orange.500' : 'green.600'}>
          {size}
        </Heading>
      </Flex>
    </SkeletonText>
  );
};

export default RamalQueue;
