import React, { useCallback, useEffect, useState } from 'react';
import {
  Flex,
  Heading,
  SkeletonCircle,
  SkeletonText,
  useToast,
} from '@chakra-ui/react';

import IResponsible from '../../Types/IResponsible';
import api from '../../Services/api';
import Avatar from '../Avatar';

const Responsible: React.FC = () => {
  const toast = useToast();

  const [loading, setLoading] = useState(true);
  const [responsibles, setResponsibles] = useState<IResponsible[]>();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.get('/settings/responsavel_semana/hoje');
      if (data.error) {
        setLoading(false);
      } else {
        setResponsibles(data);
        setLoading(false);
      }
    } catch {
      toast({
        title: 'Erro',
        status: 'error',
        position: 'top',
        description: 'Falha ao carregar responsáveis da semana',
      });
    }
  }, [toast]);

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 960000);
    return () => clearInterval(interval);
  }, [fetchData]);

  return (
    <Flex gap={2} alignItems="center" justifyContent="space-between">
      <SkeletonText noOfLines={2} isLoaded={!loading}>
        <Flex direction="column" gap={2}>
          <Heading size="md">
            Responsáve{responsibles && responsibles?.length > 1 ? 'is' : 'l'}
          </Heading>
          {responsibles && (
            <Heading size="sm">
              {responsibles.map((x) => x.username.split(' ')[0]).join(', ')}
            </Heading>
          )}
        </Flex>
      </SkeletonText>
      {loading ? (
        <SkeletonCircle />
      ) : (
        responsibles?.map((user) => <Avatar src={user?.picture} />)
      )}
    </Flex>
  );
};

export default Responsible;
