import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Flex,
  Heading,
  SkeletonCircle,
  SkeletonText,
  useToast,
} from '@chakra-ui/react';

import toDateText from '../../Utils/toDateText';
import IBirthday from '../../Types/IBirthday';
import api from '../../Services/api';
import Avatar from '../Avatar';

interface IBirthdayProps {
  index: number;
}

const Birthday: React.FC<IBirthdayProps> = ({ index }) => {
  const toast = useToast();

  const [loading, setLoading] = useState(true);
  const [birthday, setBirthday] = useState<IBirthday>();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await api.get('/settings/birthdays');
      setBirthday(data[index]);
      setLoading(false);
    } catch {
      toast({
        title: 'Erro',
        status: 'error',
        position: 'top',
        description: 'Falha ao carregar aniversariante',
      });
    }
  }, [index, toast]);

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 960000);
    return () => clearInterval(interval);
  }, [fetchData]);

  const today = useMemo(() => {
    if (!birthday) {
      return false;
    }

    const now = new Date();
    const date = new Date(`${birthday.birthday.split('T')[0]}T03:00:00.000Z`);

    if (
      now.getMonth() === date.getMonth() &&
      (now.getDate() === date.getDate() ||
        now.getDate() === date.getDate() - 1 ||
        now.getDate() === date.getDate() + 1)
    ) {
      return true;
    }

    return false;
  }, [birthday]);

  return (
    <Flex
      gap={2}
      alignItems="center"
      justifyContent="space-between"
      color={today ? 'green.600' : 'unset'}
    >
      <SkeletonText noOfLines={2} isLoaded={!loading}>
        {birthday && (
          <Flex direction="column" gap={2}>
            <Heading size="md">
              {toDateText(birthday.birthday)}
              {today && ' 🎉'}
            </Heading>
            <Heading size="sm">{birthday?.name}</Heading>
          </Flex>
        )}
      </SkeletonText>
      {loading ? <SkeletonCircle /> : <Avatar src={birthday?.picture} />}
    </Flex>
  );
};

export default Birthday;
