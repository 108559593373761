import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Text,
  chakra,
  useToast,
  SkeletonText,
} from '@chakra-ui/react';
import api from '../../Services/api';

interface IGoal {
  type: 'mensal' | 'trimestral' | 'anual';
}

const Goal: React.FC<IGoal> = ({ type }) => {
  const [loading, setLoading] = useState(true);
  const [amount, setAmount] = useState<number>(0);
  const [goal, setGoal] = useState<number>(0);

  const toast = useToast();

  const fetchData = useCallback(async () => {
    try {
      const { data: amountResult } = await api.get(`/vhsys/pedidos_${type}`);
      setAmount(amountResult.data);
      const { data: goalResult } = await api.get(
        `/settings/get_metas?type=meta_${type}`
      );
      setGoal(goalResult.data[`meta_${type}`]);
      setLoading(false);
    } catch {
      toast({
        title: 'Erro',
        status: 'error',
        position: 'top',
        description: `Falha ao carregar a meta ${type}`,
      });
    }
  }, [toast, type]);

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 960000);
    setGoal(0);
    setAmount(0);
    return () => clearInterval(interval);
  }, [fetchData]);

  const percent = useMemo<string>(() => {
    if (!amount || !goal) {
      return '...';
    }

    return `${((amount / goal) * 100).toFixed()}`;
  }, [amount, goal]);

  return (
    <Flex gap={4} alignItems="center" direction="column">
      <Box style={{ whiteSpace: 'pre-wrap' }}>
        <CircularProgress
          size="100px"
          thickness={10}
          value={percent ? Number(percent) : 0}
          color="green.600"
        >
          <CircularProgressLabel
            display="flex"
            justifyContent="center"
            fontWeight="bold"
            fontSize={18}
            w="100%"
          >
            {percent}%
          </CircularProgressLabel>
        </CircularProgress>
      </Box>
      <Box>
        <SkeletonText isLoaded={!loading} noOfLines={2}>
          <Text>
            <chakra.span fontWeight="bold" color="green.600">
              R$ {amount?.toLocaleString()}
            </chakra.span>{' '}
            /{' '}
            <chakra.span whiteSpace="nowrap">
              R$ {goal?.toLocaleString()}
            </chakra.span>
          </Text>
        </SkeletonText>
      </Box>
    </Flex>
  );
};

export default Goal;
